import React from 'react'
import Layout from '../../components/layout/layout'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import SEO from "../../components/seo"
import CCBreadcrumb from '../../components/layout/breadcrumb'
import SideBarSeThree from "../../components/layout/sidemenu/sidemenu_se_three"

import Alert from 'react-bootstrap/Alert'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'

import ContentHeader from '../../components/page-sections/content-header'
import FocusQuestion from '../../components/page-sections/focus-question'
import Introduction from '../../components/page-sections/introduction'
import ButtonTop from '../../components/buttons/button-top'

import rowImage from '../../queries/images/row-image'

import unit3Icon from '../../images/icons/unit_03_icon.svg'


const CarbonClaims35 = (props) => (
  <Layout location={props.location.pathname}>
    <SEO title="Carbon Connections - 3.5 Carbon Claims" />
    <Container className="mb-5" fluid>
      <Row>
        <Col md="auto">
          <SideBarSeThree location={props.location.pathname} />
        </Col>
        <Col>
          <CCBreadcrumb
            pathname={props.location.pathname}
            title={'3.5 Carbon Claims'}
            replace={props.replace}
          />
          <Alert
            className="w-100"
            variant="warning"
          >
            BSCS is maintaining this educational resource for archival purposes. Some information may be out of date. We are no longer supporting the resource for classroom use.
          </Alert>
          <Card>
            <Card.Body>

              <ContentHeader
                icon={ unit3Icon }
                iconAlt="Unit 3 icon"
                studentPageHeader="Unit 3: Carbon in the Future &amp; You"
                unitNumber="3.5"
                studentPageHeaderSub="Carbon Claims"
                sectionA={ <>Carbon Claims</> }
                sectionALink="/unit-3/3.5-carbon-claims/#key-things-you-learned"
                sectionB={ <>You: The Climate Critic</> }
                sectionBLink="/unit-3/3.5-carbon-claims/#you-the-climate-critic"
              />

              <FocusQuestion
                focusQuestionBodyText={ <>How can I use models and data from <em>Carbon Connections</em> to test claims about carbon and climate?</> }
              />

              <Introduction
                paragraphOne={
                  <>
                    <Row className="d-md-none">
                      <Col>
                        <Img
                          className="rounded img-fluid mb-3"
                          fluid={props.data.image40.childImageSharp.fluid}
                          alt="Bottle experiment"
                        />
                      </Col>
                    </Row>
                    <Img
                      className="smallImageRight ml-3 mb-3 img-fluid d-none d-md-block"
                      fluid={props.data.image40.childImageSharp.fluid}
                      alt="Bottle experiment"
                    />

                    <p>Climate and the carbon cycle. It has been a journey for you and your class. Maybe this is your first time learning about science online?</p>
                  </>
                }

                paragraphTwo={
                  <>
                    <p>From these three units, you can better see how you are connected to carbon, how carbon connects to your oceans, atmosphere, and land, and how some of the decisions you make every day are connected to carbon. Those are carbon connections.</p>
                  </>
                }

                paragraphThree={
                  <>
                    <p>But what are your friends saying? Do they ever mention carbon and climate? Other classes like yours have also been using <em>Carbon Connections</em>. Some of the students have shared their ideas through their favorite social networking site. You can see what they are posting about some of the models in Unit 3. In this lesson, you will demonstrate that you can:</p>
                    <ul>
                      <li>Critically evaluate claims about carbon and climate science.</li>
                      <li>Use scientific models to test relationships in systems.</li>
                      <li>Learn more about connections among science and topics for society.</li>
                    </ul>
                  </>
                }
              />

              <ButtonTop />

              <Row>
                <Col>
                  <Card className="mb-4">
                    <Card.Header id="key-things-you-learned" className="cardHeader">
                      <h2>
                        A. <span className="headerFontBlue">Key Things You Learned</span>
                      </h2>
                    </Card.Header>
                    <Card.Body className="cardBodyBackground">
                      <p>Before you start your activity, let's review what you've learned.</p>

                      <p className="lead"><em>Lesson 3.1</em></p>
                      <ul>
                        <li>A network of climate stations gives a single average for global temperature per month.</li>
                        <li>The global temperature varies, but it also shows an overall increasing temperature.</li>
                        <li>Satellite-based temperature data confirm the ground-based temperature data.</li>
                      </ul>

                      <p className="lead"><em>Lesson 3.2</em></p>
                      <ul>
                        <li>Some climate forcings lead to a change in Earth's average temperature.</li>
                        <li>Some climate forcings lead to pattern of cycles in Earth's average temperature, but no net change.</li>
                        <li>Scientific models help you understand relationships in the Earth system.</li>
                      </ul>

                      <p className="lead"><em>Lesson 3.3</em></p>
                      <ul>
                        <li>Variability in the Earth system over periods of years is different from gradual change in the system over periods of decades.</li>
                        <li>Models show how Earth's climate may change in the future with changing CO<sub>2</sub> levels in the atmosphere.</li>
                        <li>Models show how Earth's climate may change in the future with changing CO<sub>2</sub> levels in the atmosphere.</li>
                      </ul>

                      <p className="lead"><em>Lesson 3.4</em></p>
                      <ul>
                        <li>How to measure energy use by appliances in your home.</li>
                        <li>The difference between energy conservation and increased energy efficiency.</li>
                        <li>How to reduce your carbon footprint, as well as your monthly energy bill.</li>
                      </ul>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <ButtonTop />

              <Row>
                <Col>
                  <Card className="mb-4">
                    <Card.Header id="you-the-climate-critic" className="cardHeader">
                      <h2>
                        B. <span className="headerFontBlue"> You: The Climate Critic</span>
                      </h2>
                    </Card.Header>
                    <Card.Body className="cardBodyBackground">
                      <Row className="d-md-none">
                        <Col>
                          <Img
                            className="rounded img-fluid mb-3"
                            fluid={props.data.image41.childImageSharp.fluid}
                            alt="A girl using her laptop"
                          />
                        </Col>
                      </Row>
                      <Img
                        className="smallImageRight rounded mb-3 ml-3 img-fluid d-none d-md-block"
                        fluid={props.data.image41.childImageSharp.fluid}
                        alt="A girl using her laptop"
                      />

                      <p>
                        Have you been to a movie lately? If you have, you probably heard what the critics, or even your friends, had to say about it before you even went to the movie. Did they claim it was funny? Scary? Did they say <em>why</em> they thought it was funny or scary?
                      </p>

                      <p>
                        In this lesson, you get to be a critic&mdash;a climate critic. You will evaluate what your friends or other students like you are saying about carbon and climate via social media. You can see this in their personal posts, which they've been sharing on their favorite social networking site. They've posted a video, photo, and comments.
                      </p>

                      <p>
                        But how are students like you doing with their understanding of carbon and climate. You get to be the climate critic regarding their posts. Do the steps below to evaluate what students like you are thinking about carbon and climate.
                      </p>

                      <ol>
                        <li>
                          Join with two partners to review and discuss the science in the posts. This discussion will be important for you when you have to answer the questions by yourself in step 3.
                          <ol type="a">
                            <li>View the video posted by Noelle to see what she's been up to this week. Noelle has just finished <em>Carbon Connections</em> in class. Noelle and her friend Hayden are your friends at other high schools in your region.</li>

                            <div id="" className="embed-container mb-3 mt-3">
                              <iframe title="SOCIAL_NETWORKING" className="videoSetting" src="https://player.vimeo.com/video/310394239" frameBorder="0" webkitallowfullscreen={toString('true')} mozallowfullscreen={toString('true')} allowFullScreen></iframe>
                            </div>

                            <li>View the three posts by Noelle's friends. They are in other science classes. </li>
                          </ol>
                        </li>
                      </ol>

                      <p>
                        For the video and each of the three posts, discuss with your team:
                      </p>

                      <ol start="2">
                        <li>
                          Is their understanding of carbon and climate accurate? Review the posts as needed. <br />
                          <em>Hint: You should be able to find one error to address or question to answer in each.</em>
                          <ol type="a">
                            <li>If a claim about carbon and climate is not clear or accurate, discuss why with your group. How could you correct his or her claim or question? For example, if a claim is incorrect, discuss as a group what would make it accurate. Or if a post asks a climate question, discuss the data that would answer the question from the student.</li>
                            <li>
                              With your team, use the most pertinent interactive from Unit 3 to correct each error or answer each question. You should use each of these interactives at least once:
                              <ul>
                                <li>
                                  <Link to="/unit-3/3.1-your-temperature-connections">Ta Temperature Explorer (Lesson 3.1)</Link>
                                </li>
                                <li>
                                  <Link to="/unit-3/3.2-testing-forcings"><em>Carbon Connections</em> Climate Model (Lesson 3.2)</Link>
                                </li>
                                <li>
                                  <Link to="/unit-3/3.3-future-forcings">Carbon Budget Model (Lesson 3.3)</Link>
                                </li>
                                <li>
                                  <Link to="/unit-3/3.4-it-starts-at-home">Carbon Calculator (Lesson 3.4)</Link>
                                </li>
                              </ul>
                            </li>
                            <li>Use a table like the one below with your team to help organize your findings. Your team should complete columns 1 and 2. This will help with your individual reporting in step 3.</li>
                          </ol>
                        </li>
                      </ol>

                      <table className="table table-bordered table-hover table-responsive-md studentTable">
                        <thead>
                          <tr className="text-center">
                            <th className="table-active" scope="col">Error OR Question Number</th>
                            <th className="table-active" scope="col">Number	Incorrect Statement, OR Question to Answer</th>
                            <th className="table-active" scope="col">Resources, Interactives Used by your Team</th>
                            <th className="table-active" scope="col">Corrected Statement, OR Answer to Question</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="text-center">1</td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <td className="text-center">2</td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <td className="text-center">continue here</td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>

                      <ol start="3">
                        <li>
                          Work individually to prepare your materials to turn in to your teacher:
                          <ol type="a">
                            <li>Review the table from your team for each error to correct or question that you could answer.</li>
                            <li>For column 3, write a clear answer, or "critique" for each.</li>
                            <li>
                              Along with each of your answers, be sure to list any other references or resources that you use. This includes <em>Carbon Connections</em> or other references. <br />
                              <em>NOTE: If you use an interactive model to show your answer, get a screen shot of your model. You can use this to support and document your answer.</em>
                            </li>
                          </ol>
                        </li>
                        <li>Listen to your teacher for directions about turning in your climate critic assignment.</li>
                      </ol>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <ButtonTop />

              <Row>
                <Col>
                  <Card className="mb-4">
                    <Card.Header className="cardHeader">
                      <h2>
                        <span className="headerFontBlue">Social Media Climate Critic Video Transcript</span>
                      </h2>
                    </Card.Header>
                    <Card.Body className="cardBodyBackground">
                      <p className="lead">Social Media Climate Critic Video Transcript</p>
                      <p><em><strong>Noelle:</strong> Hey!</em></p>

                      <p><em><strong>Hayden:</strong> How's your day going?</em></p>

                      <p>
                        <em><strong>Noelle:</strong> Uugghh, Coach Alex is really workin' us in basketball. The court sprints are killing me&mdash;and I'm really sore.</em>
                      </p>

                      <p>
                        <em><strong>Hayden:</strong> That's no fun. I'm really excited for our school musical this spring. I plan to try out for one of the lead parts this year. Are you gonna trying out?</em>
                      </p>

                      <p>
                        <em><strong>Noelle:</strong> Not sure yet&hellip; so much going on with basketball, swimming, and classes</em>
                      </p>

                      <p><em><strong>Hayden:</strong> Yeah, I'm busy at school, too.</em></p>

                      <p>
                        <em>In science class this week we're doing some stuff that we haven't done before. We've been using these computer models where you can test things about earth's climate. You can see how carbon might relate to climate change. We read something like how carbon is part of climate because most of our electricity comes from using fossil fuels. It's actually sort of interesting.</em>
                      </p>

                      <p>
                        <em><strong>Noelle:</strong> That does sound interesting. I mean, climate change is big deal these days. I'm learning about it, too.</em>
                      </p>

                      <p>
                        <em><strong>Hayden:</strong> Yeah, we're also learning other climate stuff. I didn't know that the average temperature for Earth has increased each year for the last 30 years or so.</em>
                      </p>

                      <p><em><strong>Noelle:</strong> How do scientists see that?</em></p>

                      <p>
                        <em><strong>Hayden:</strong> I'm not quite sure. We saw some maps online that show where temperature is measured. Most temperature stations are on land, so it seems like you would just be getting temperatures from land. Since most of Earth is oceans, how can scientists really tell if Earth is getting warmer?</em>
                      </p>

                      <p>
                        <em><strong>Noelle:</strong> Well, in my class, my chemistry teacher talked about the carbon cycle and carbon in the atmosphere. I guess carbon in the air is called carbon dioxide gas. It's a greenhouse gas that leads to global warming.</em>
                      </p>

                      <p><em><strong>Hayden:</strong> How?</em></p>

                      <p>
                        <em><strong>Noelle:</strong> I don't know exactly. I'm not sure I believe the evidence we've studied about global warming. I saw a graph that showed the global "mean" temperature. It showed that 1998 was about the hottest year on record.</em>
                      </p>

                      <p><em><strong>Hayden:</strong> Here in Colorado, or everywhere?</em></p>

                      <p>
                        <em><strong>Noelle:</strong> No, everywhere. But temperatures don't really seem to get much hotter after that, so how can there be global warming? When I look at temperatures after about 2002, I'm not sure that they're increasing.</em>
                      </p>

                      <p><em><strong>Hayden:</strong> Can I see?</em></p>

                      <p>
                        <em><strong>Noelle:</strong> Sure, I'll send the graph we've been studying. I wonder what Becky and Sam are up to this week. All right&hellip; well, I have to go. Bye!</em>
                      </p>

                      <p><em><strong>Hayden:</strong> Later&hellip;</em></p>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <ButtonTop />

              <Row>
                <Col>
                  <Card className="mb-4">
                    <Card.Header className="cardHeader">
                      <h2>
                        <span className="headerFontBlue">POST 1: Noelle</span>
                      </h2>
                    </Card.Header>
                    <Card.Body className="cardBodyBackground">
                      <p>
                        Hi Hayden: Here's the graph I was talking about&hellip; Talk later!
                      </p>

                      <Row className="d-md-none">
                        <Col>
                          <Img
                            className="rounded img-fluid mb-3"
                            fluid={props.data.image42.childImageSharp.fluid}
                            alt="Global temperature record, 1970-2010 chart"
                          />
                        </Col>
                      </Row>
                      <Img
                        className="largeImageCenter rounded mb-4 img-fluid d-none d-md-block"
                        fluid={props.data.image42.childImageSharp.fluid}
                        alt="Global temperature record, 1970-2010 chart"
                      />
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Card className="mb-4">
                    <Card.Header className="cardHeader">
                      <h2>
                        <span className="headerFontBlue">POST 2: Sam</span>
                      </h2>
                    </Card.Header>
                    <Card.Body className="cardBodyBackground">
                      <p>Yo!</p>

                      <p>Basketball sounds rough. Ouch!</p>

                      <p>
                        So our family was talking at dinner the other night about that carbon and climate stuff. My older sister was thinking that converting forests to farmland could help both humans and climate for a few reasons:
                      </p>
                      <ul>
                        <li>You can use wood from trees for buildings.</li>
                        <li>Farms can help to feed humans.</li>
                        <li>Farm crops are a major carbon trap for the atmosphere because they remove CO<sub>2</sub> from the air and store that carbon.</li>
                      </ul>

                      <p>It made sense to me, anyway. Right?</p>

                      <p>
                        See ya! <br />
                        Sam
                      </p>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Card className="mb-4">
                    <Card.Header className="cardHeader">
                      <h2>
                        <span className="headerFontBlue">POST 3: Rebecca</span>
                      </h2>
                    </Card.Header>
                    <Card.Body className="cardBodyBackground">
                      <p>Hi there,</p>

                      <p>
                        Hayden, you're a great singer, so I know you'll get a great part in the musical! Noelle, sorry to hear you're in pain&hellip;take care!
                      </p>

                      <p>
                        Your science class sounds different than mine. Mine is mostly lectures&hellip;
                      </p>

                      <p>
                        I've been thinking about making my carbon footprint smaller at home. I saw that carbon calculator on that website for your class. I can't tell which categories make the bigger difference in my footprint. I think it's food and diet, because all my brothers eat so much! I'll test THAT in the model. LOL!
                      </p>

                      <p>
                        So, I have a cousin in Oregon, and it might be fun to use the carbon calculator to compare our family here in Colorado with her family in Oregon&mdash;she has the same number of brothers. I wonder what would happen if I compared my family with a similar family in the state that is far away from where we live??? What results would that give?
                      </p>

                      <p>
                        Hmmm, now that I think about it, I wonder how our family compares with the overall average for the U.S.?
                      </p>

                      <p>Anyway, see you around!</p>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Card className="mb-4">
                    <Card.Body className="cardBodyBackground">
                      <p>
                        CONGRATULATIONS! You've completed <em>Carbon Connections</em>. After completing these lessons, interactives, and hands-on activities, you should have a better understanding of the carbon cycle and climate science.
                      </p>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <ButtonTop />

            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default CarbonClaims35

export const query = graphql`
  query {
    image40: file(relativePath: { eq: "student-pages/unit-3/40_bottle_experiment.jpg" }) {
      ...rowImage
    }
    image41: file(relativePath: { eq: "student-pages/unit-3/41_girl_on_laptop.jpg" }) {
      ...rowImage
    }
    image42: file(relativePath: { eq: "student-pages/unit-3/42_global_temp_record_nasa_british_t.png" }) {
      ...rowImage
    }
  }
`
